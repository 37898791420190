<template>
  <!-- Table section -->
  <v-card>
    <v-data-table
      mobile-breakpoint="200"
      :headers="headers"
      :items="mDataArray"
      :loading="dataTableLoading"
      hide-default-footer
      disable-pagination
      disable-filtering
      disable-sort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.ref_product_id }}</td>
          <td>{{ item.branch }}</td>
          <td>
            <v-img
              v-if="item.product_image"
              :src="item.product_image | imageUrl"
              max-height="50"
              max-width="50"
              aspect-ratio="1"
              class="ma-1"
            />
          </td>
          <td>
            {{ item.product }}
            <span class="grey--text">{{ item.product_subname }}</span>
          </td>
          <td>{{ item.total | currency("", 0) }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "ProductReportCard",
  props: ["durations"],
  mounted() {
    //console.log('branch_id', this.branch_id);
    //console.log("duration", this.durations)
  },
  data() {
    return {
      dataTableLoading: false,
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "Branch", value: "branch" },
        { text: "Image", value: "image" },
        { text: "Product", value: "product" },
        { text: "Qty", value: "qty" },
      ],
    };
  },
  computed: {},
  watch: {
    // branch_id: {
    //     handler(newVal, oldVal) {
    //         console.log('watch:', newVal)
    //         this.getData();
    //     }, deep: true
    // }
  },
  methods: {
    async getData(id) {
      this.branch_id = id;

      this.dataTableLoading = true;
      //console.log(this.branch_id, this.durations)
      let duration = this.durations.join("&");
      let result = await api.getReportByBranch(this.branch_id, duration);
      if (result.data.result == "ok") {
        //console.log(JSON.parse(result.data.message));
        this.mDataArray = JSON.parse(result.data.message);
      }
      this.dataTableLoading = false;
    },
    resetData() {
      this.mDataArray = [];
    },
  },
  beforeDestroy: function() {
    delete this.mDataArray;
  }
};
</script>

<style></style>
