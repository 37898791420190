<template>
  <v-container fluid grey lighten-3>
    <v-container fluid mt-5 mb-5>
      <!-- filter brand and date picker -->
      <v-row>
        <v-col md="6" cols="12">
          <h2 class="mb-5">BRANCH REPORT</h2>
        </v-col>
        <v-col cols="12" md="4">
          <v-dialog
            ref="dialogDatepick"
            v-model="date_modal"
            :return-value.sync="dates"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="เลือก วันที่"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="date_modal = false"
                >Cancel</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.dialogDatepick.save(dates)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn text class="mt-4 pl-0 ml-0" @click="getData"
            ><v-icon>search</v-icon> ค้นหา</v-btn
          >
        </v-col>
      </v-row>

      <!-- Table section -->
      <v-card>
        <v-data-table
          mobile-breakpoint="200"
          :headers="headers"
          :items="mDataArray"
          :loading="dataTableLoading"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.name }}</td>
              <td class="blue lighten-5">{{ item.total_order | currency('', 0) }}</td>
              <td class="blue lighten-4">{{ item.amount | currency("", 2) }}</td>
              <td class="red lighten-4">{{ item.cost | currency("", 2) }}</td>
              <td class="yellow lighten-4">{{ item.shipping_cost | currency('', 2) }}</td>
              <td class="yellow lighten-4">{{ item.cod_cost | currency('', 2) }}</td>
              <td class="grey lighten-4">{{ item.total_kerry | currency('',0) }}
                <span class="caption">(<span class="orange--text text--darken-4">{{item.totalfreight_kerry|currency('',2)}}</span> : {{item.totalcodfee_kerry|currency('',2)}})</span></td>
              <td class="grey lighten-4">{{ item.total_flash | currency('',0) }}
                <span class="caption">(<span class="orange--text text--darken-4">{{item.totalfreight_flash|currency('',2)}}</span> : {{item.totalcodfee_flash|currency('',2)}})</span></td>
              <td class="">
                <span :class="checkProfit(item.amount, item.cost)">{{
                  (item.amount - item.cost) | currency("฿")
                }}</span>
              </td>
              <td class="">
                <span :class="checkProfitWithShipping(item.amount, item.cost, item.shipping_cost, item.cod_cost)"><b>{{
                  (item.amount-item.cost-item.shipping_cost-item.cod_cost) | currency("฿")
                }}</b></span>
              </td>
              <td><v-btn v-if="item.id != '###'" @click="searchBranch(item.id)" text small><v-icon>search</v-icon></v-btn></td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-container fluid>
        <v-row>
            <v-col>
                <h2 class="mb-5">PRODUCT REPORT</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ProductReportCard :durations="dates" ref="ProductReportCard"></ProductReportCard>
            </v-col>

        </v-row>
    </v-container>
  </v-container>
</template>

<script>
import api from "@/services/api";
import _ from "lodash";

import ProductReportCard from "@/components/reports/ProductReportCard";

export default {
  name: "Report",
  components: {
      ProductReportCard
  },
  data() {
    return {
      branch_id: '',
      dates: ["", ""],
      date_modal: false,
      dataTableLoading: false,
      mDataArray: [],
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Branch", value: "branch", },
        { text: "Order", value: "total_order", class: "blue lighten-1 white--text" },
        { text: "Amount", value: "amount", class: "blue lighten-2" },
        { text: "Cost", value: "cost", class: "red lighten-1" },
        { text: "Freight (in.vat)", value: "shipping_cost",class: "yellow lighten-2" },
        { text: "Cod fee (ex.vat)", value: "cod_cost",class: "yellow lighten-1" },
        { text: "Kerry fre:codfee", value: "total_kerry", class: "" },
        { text: "Flash fre:codfee", value: "total_flash", class: "" },
        { text: "Profit", value: "profit",class: "green lighten-2" },
        { text: "P-S กำไรสุทธิ", value: "",class: "light-green lighten-2" },
        { text: "More", value: "detail"}
      ]
    };
  },
  mounted() {
    // this.start = this.$moment().subtract(1, 'months').format("YYYY-MM-01");
    // let now = new Date()
    // let year = now.getFullYear();
    // let month = _.padStart((now.getMonth()+1), 2, '0');
    // let date = _.padStart((now.getDate()), 2, '0');

    // this.dates[0] = `${year}-${month}-01`;
    // this.dates[1] = `${year}-${month}-${date}`;

    this.getData();
  },
  computed: {
    dateRangeText() {
      if (!this.dates[0] && !this.dates[1]) {
        this.initDateFilter();
      }
      return this.dates.join("~");
    }
  },
  methods: {
    searchBranch: _.debounce(function(id) {
        if(id != '###') {
            //console.log('branch', id);
            this.branch_id = id;
            this.$refs.ProductReportCard.getData(id);
        }
    },300),
    initDateFilter() {
      let now = new Date();
      let year = now.getFullYear();
      let month = _.padStart(now.getMonth() + 1, 2, "0");
      let date = _.padStart(now.getDate(), 2, "0");

      this.dates[0] = `${year}-${month}-01`;
      this.dates[1] = `${year}-${month}-${date}`;
    },
    async getData() {
      this.$refs.ProductReportCard.resetData();
      this.dataTableLoading = true;
      // let dparams = '2020-01-01&2020-02-29';
      let dparams = this.dates.join("&");
      let result = await api.getReport(dparams);
      if (result.data.result == "ok") {
        //console.log(result.data.message)
        this.mDataArray = JSON.parse(result.data.message);
        // console.log(this.mDataArray)
      }
      this.dataTableLoading = false;
    },
    checkProfit(val, val2) {
      return val - val2 > 0 ? "grey--text text--darken-3" : "red--text";
    },
    checkProfitWithShipping(val, val2, val3, val4) {
      return val-val2-val3-val4 > 0 ? "green--text" : "red--text";
    }
  },
  beforeDestroy: function() {
    delete this.mDataArray;
  }
};
</script>

<style></style>
